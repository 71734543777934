import { useContext, useEffect, useState } from "react";
import { SerialServiceContext } from ".";

type PanelDetails = {
  panelId: string;
  buttonCommand: string[];
};

enum ButtonType {
  DimmerLight = "dimmer_light",
  Light = "light",
}

function App() {
  const serialService = useContext(SerialServiceContext);
  const [isConnected, setIsConnected] = useState(false);
  const [commands, setCommands] = useState<string[]>([]);
  const [configuredList, setConfiguredList] = useState<string[]>([]);
  const [parableList, setParableList] = useState<string[]>([]);

  const [viewPanelButtons, setViewPanelButtons] =
    useState<PanelDetails | null>();
  const [sliderValues, setSliderValues] = useState<{ [key: string]: number }>(
    {}
  );
  const [buttonStates, setButtonStates] = useState<{ [key: string]: boolean }>(
    {}
  );
  const sliderValueChanged = (buttonId: string, val: number) => {
    setSliderValues((prev) => ({
      ...prev,
      [buttonId]: val,
    }));
  };

  const parseButtonCommand = (cmd: string) => {
    const parts = cmd.split(",");
    return {
      number: parts[2],
      type: parts[3] as ButtonType,
      btnstatus: parts[4]?.trim() === "on",
      dimmevalue: parts[5],
    };
  };

  function oncommand(text: string) {
    console.log("text", text);
    setCommands((command) => [...command, text]);
  }
  useEffect(() => {
    if (viewPanelButtons) {
      viewPanelButtons.buttonCommand.forEach((cmd) => {
        const { number, type, dimmevalue } = parseButtonCommand(cmd);

        if (type === ButtonType.DimmerLight) {
          const dimmerValue = parseInt(dimmevalue, 10);
          setSliderValues((prev) => ({
            ...prev,
            [number]: dimmerValue,
          }));
        }
      });
    }
  }, [viewPanelButtons]);
  useEffect(() => {
    serialService.commandState = oncommand;
  }, []);

  const isChrome = "chrome" in window;

  if (!isChrome) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen m-10">
        <h1 className="text-3xl">Unsupported Browser</h1>
      </div>
    );
  }

  const getConfiguredPanels = async () => {
    console.log("fetching...");
    await serialService.write("getallpanels\r\n");
  };

  const handleButtonClick = async (
    macId: string,
    number: string,
    sliderValue?: number,
    state?: "on" | "off"
  ) => {
    console.log(
      `Number: ${number}, State: ${state} Slider Value: ${sliderValue}`
    );
    if (state === "off") {
      setSliderValues((prev) => ({
        ...prev,
        [number]: 0,
      }));
    }
    setButtonStates((prev) => ({
      ...prev,
      [number]: state === "on",
    }));

    await serialService.write(
      `sendrf,ucast,${macId},<req,${state},${number},${sliderValue},null>`
    );
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen m-0">
      {viewPanelButtons && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
            <h2 className="text-xl font-semibold mb-2 border-b pb-2">
              Panel Buttons
            </h2>
            <div className="mb-0">
              <strong className="block text-gray-700 mb-0">Panel ID:</strong>
              <div className="text-gray-800">{viewPanelButtons.panelId}</div>
            </div>

            <ul className="space-y-2">
              {viewPanelButtons.buttonCommand.map((cmd) => {
                             const { number, type, btnstatus,  } = parseButtonCommand(cmd);


                if (!Object.values(ButtonType).includes(type)) {
                  return null; 
                }

                return (
                  <div key={number} className="border-b pb-2">
                    <li className="flex flex-col gap-2">
                      <div className="flex justify-between items-center">
                        <span className="text-gray-600 font-medium">
                          Button {number}
                        </span>
                         <ToggleSwitch
                          buttonId={number}
                          macId={viewPanelButtons.panelId}
                          sliderValue={sliderValues[number] || 0}
                          isOn={buttonStates[number] ?? btnstatus}
                          onToggle={(newState:any) =>
                            handleButtonClick(
                              viewPanelButtons.panelId,
                              number,
                              sliderValues[number],
                              newState ? "on" : "off"
                            )
                          }
                        />
                      </div>
                      {type === "dimmer_light" && (
                        <RangeSlider
                          buttonId={number}
                          macId={viewPanelButtons.panelId}
                          value={sliderValues[number] || 0}
                          onChange={(value: number) =>
                            sliderValueChanged(number, value)
                          }
                          handleButtonClick={handleButtonClick}
                          classes="w-full"
                        />
                      )}
                    </li>
                  </div>
                );
              })}
            </ul>

            <button
              className="absolute top-2 right-2 text-red-500 font-bold text-lg"
              onClick={() => setViewPanelButtons(null)}
            >
              ×
            </button>
          </div>
        </div>
      )}

      <div className="flex flex-row gap-4">
        <h1 className="text-3xl">
          Start Config [{isConnected ? "Connected" : "Not Connected"}]
        </h1>
        <button
          className="px-5 py-2 bg-slate-400 text-white font-bold rounded-lg"
          onClick={async () => {
            if (isConnected === true) {
              await serialService.disconnect();
              setIsConnected(false);
            } else {
              serialService.connect(
                (status) => {
                  setIsConnected(status);
                  setConfiguredList([]);
                  setParableList([]);
                  getConfiguredPanels();
                },
                (text) => {
                  const [action, data] = text.split(":");

                  const rowAction = action.trim();
                  const rowData = data
                    ?.replaceAll("<", "")
                    ?.replaceAll(">", "");

                  if (rowAction.includes("PN_")) {
                    const [_, macAddress] = rowAction.split("PN_");
                    setViewPanelButtons((obj) => {
                      if (obj == null) {
                        obj = {
                          panelId: macAddress,
                          buttonCommand: [rowData],
                        };
                      } else if (obj.panelId == macAddress) {
                        obj.buttonCommand.push(rowData);
                      } else {
                        obj = {
                          panelId: macAddress,
                          buttonCommand: [rowData],
                        };
                      }
                      return obj;
                    });
                  }

                  if (rowAction === "SL") {
                    if (rowData.trim().length === 0) {
                      setParableList([]);
                      return;
                    }

                    const list = rowData
                      .split(",")
                      .map((panel) => panel.trim());

                    setParableList(list);
                  } else if (rowAction === "PR") {
                    if (rowData.trim().length === 0) {
                      setConfiguredList([]);
                      return;
                    }

                    const list = rowData
                      .split(",")
                      .map((panel) => panel.trim());

                    setConfiguredList(list);
                  } else if (rowAction === "PROK") {
                    getConfiguredPanels();
                  } else if (rowAction === "RMOK") {
                    getConfiguredPanels();
                  }
                }
              );
            }
          }}
        >
          {isConnected ? "Disconnect" : "Connect"}
        </button>
      </div>

      <BodyComponent
        configuredList={configuredList}
        getConfiguredPanels={getConfiguredPanels}
        parableList={parableList}
        isConnected={isConnected}
        commands={commands}
      />
    </div>
  );
}

function BodyComponent({
  getConfiguredPanels,
  configuredList,
  parableList,
  isConnected,
  commands,
}: {
  getConfiguredPanels: () => void;
  configuredList: string[];
  parableList: string[];
  commands: string[];
  isConnected: boolean;
}) {
  const serialService = useContext(SerialServiceContext);
  const [isScanning, setIsScanning] = useState(false);

  if (!isConnected) {
    return <div />;
  }

  return (
    <div className="flex items-start w-screen p-2">
      <div className="w-1/2">
        <div className="flex flex-row gap-1">
          <div className="font-semibold text-xl">Connected Panels</div>
          <button
            onClick={() => {
              getConfiguredPanels();
            }}
          >
            Refresh
          </button>
        </div>
        {configuredList.map((panel) => {
          return (
            <div className="flex flex-row gap-6 items-center my-2" key={panel}>
              <div>{panel}</div>
              <button
                className="px-2 py-1 bg-red-500 text-white font-medium rounded-lg text-xs"
                onClick={async () => {
                  await serialService.write(`rm:<${panel}>`);
                }}
              >
                REMOVE
              </button>
              <button
                className="px-2 py-1 bg-emerald-300 text-black font-medium rounded-lg text-xs"
                onClick={async () => {
                  await serialService.write(`getinfo:<${panel}>`);
                }}
              >
                GETINFO
              </button>
            </div>
          );
        })}
      </div>
      <div className="w-1/2">
        <button
          className="px-4 py-2 bg-blue-500 text-white font-bold rounded-lg"
          onClick={async () => {
            if (isScanning) {
              serialService.write("searchoff");
            } else {
              serialService.write("searchon");
            }

            setIsScanning(!isScanning);
          }}
        >
          {isScanning ? "Stop Scanning" : "Start Scanning"}
        </button>
        {parableList.map((panel) => {
          if (panel.trim().length === 0) {
            return <div />;
          }

          return (
            <div className="flex flex-row gap-6 items-center my-2" key={panel}>
              <div>{panel}</div>
              <button
                className="px-2 py-1 bg-blue-500 text-white font-medium rounded-lg text-xs"
                onClick={async () => {
                  await serialService.write(`pair:<${panel}>`);

                  // await serialService.writeRead(`add_${panel}`, kBaudRate);
                  // setConfiguredList((list) => {
                  //   return [...list, panel];
                  // });
                  // setParableList((list) => {
                  //   return list.filter((item) => item !== panel);
                  // });
                }}
              >
                Pair
              </button>
            </div>
          );
        })}
      </div>
      <div className="w-1/2 p-5">
        <div className="font-bold">Commands:</div>
        {commands.map((command, index) => (
          <div key={index}>{command}</div>
        ))}
      </div>
    </div>
  );
}

const RangeSlider = ({
  classes,
  label,
  onChange,
  value,
  buttonId,
  macId,
  handleButtonClick,
  ...sliderProps
}: any) => {
  const [sliderVal, setSliderVal] = useState(0);

  useEffect(() => {
    setSliderVal(value);
  }, [value]);

  const changeCallback = (e: any) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderVal(newValue);
  };

  const handleMouseUp = () => {
    const state = sliderVal === 0 ? "off" : "on";
    onChange(sliderVal);
    handleButtonClick(macId, buttonId, sliderVal, state);
  };

  const ticks = Array.from({ length: 6 }, (_, index) => index * 20);

  return (
    <div className="range-slider">
      <p>{label}</p>
      <h4>Value: {sliderVal}</h4>
      <input
        type="range"
        value={sliderVal}
        step={20}
        min={0}
        max={100}
        {...sliderProps}
        className={`slider ${classes}`}
        id="myRange"
        onChange={changeCallback}
        onMouseUp={handleMouseUp}
      />
      <div className="flex justify-between">
        {ticks.map((tick) => (
          <span key={tick} className="text-xs">
            {tick}
          </span>
        ))}
      </div>
    </div>
  );
};

const ToggleSwitch = ({ isOn, onToggle }: any) => {
  return (
    <div
      className={`relative w-16 h-8 flex items-center rounded-full cursor-pointer p-1 ${
        isOn ? "bg-green-500" : "bg-gray-400"
      }`} 
      onClick={() => onToggle(!isOn)}
    >
      <div
        className={`absolute w-6 h-6 bg-white rounded-full shadow-md transform transition-transform ${
          isOn ? "translate-x-8" : "translate-x-0"
        }`}
      />
    </div>
  );
};

export default App;
